.ant-slider-track {
  background-color: var(--ant-primary-4);
}

.ant-slider-handle {
  border-color: var(--ant-primary-4);
}

.ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}

.ant-modal-body {
  padding: 50px;
}

textarea.ant-input[readonly] {
  background: #f5f5f5;
}
